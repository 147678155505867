import * as dayjs from 'dayjs';
import { TaxPeriod } from './tax.types';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const getDateForPeriod = (period: TaxPeriod) => {
  switch (period) {
    case TaxPeriod.January:
      return '01-31';
    case TaxPeriod.February:
      return '02-28';
    case TaxPeriod.March:
      return '03-31';
    case TaxPeriod.April:
      return '04-30';
    case TaxPeriod.May:
      return '05-31';
    case TaxPeriod.June:
      return '06-30';
    case TaxPeriod.July:
      return '07-31';
    case TaxPeriod.August:
      return '08-31';
    case TaxPeriod.September:
      return '09-30';
    case TaxPeriod.October:
      return '10-31';
    case TaxPeriod.November:
      return '11-30';
    case TaxPeriod.December:
      return '12-31';
  }
};

export const isDateInRange = (
  targetDate: Date,
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (!startDate && !endDate) {
    return true;
  }

  if (startDate && !endDate && startDate.getTime() <= targetDate.getTime()) {
    return true;
  }

  if (!startDate && endDate && endDate.getTime() >= targetDate.getTime()) {
    return true;
  }

  return (
    startDate &&
    endDate &&
    startDate.getTime() <= targetDate.getTime() &&
    endDate.getTime() >= targetDate.getTime()
  );
};

export const getDateOverlap = (
  range1: DateRange,
  range2: DateRange,
): DateRange | null => {
  const start1 = dayjs(range1.startDate);
  const end1 = dayjs(range1.endDate);
  const start2 = dayjs(range2.startDate);
  const end2 = dayjs(range2.endDate);

  // Calculate the overlapping range
  const overlapStart = start1.isAfter(start2) ? start1 : start2;
  const overlapEnd = end1.isBefore(end2) ? end1 : end2;

  // Check if there is an overlap
  if (overlapStart.isBefore(overlapEnd) || overlapStart.isSame(overlapEnd)) {
    return {
      startDate: overlapStart.toDate(),
      endDate: overlapEnd.toDate(),
    };
  }

  // No overlap
  return null;
};
