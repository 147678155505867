import { Injectable } from '@angular/core';

import {
  MintAuthChannel,
  MintAuthService,
  MintConfigService,
  MintHttpService,
  MintLogger,
  MintRegistrationDto,
  MintStorageService,
  MintStorageType,
  MintUserModel,
  MintUserService,
  MintUserSettings,
} from '@bryllyant/mint-ngx';
import { lastValueFrom } from 'rxjs';

const logger = MintLogger.getLogger('UserService');

export const ADMIN_LOGGED_IN_AS_USER_KEY = 'admin-logged-in-as-user';

@Injectable({ providedIn: 'root' })
export class SystemService {
  constructor(
    private readonly configService: MintConfigService,
    private readonly authService: MintAuthService,
    private readonly localStorage: MintStorageService,
    private readonly userService: MintUserService,
    private readonly httpService: MintHttpService,
  ) {}

  async adminRegisterUser(
    payload: MintRegistrationDto & { isAdmin: boolean },
  ): Promise<MintUserModel> {
    const urlPath =
      this.configService.getConfig().api.service?.user ??
      MintUserModel._mintResourceName;

    return this.httpService.post(urlPath + '/admin-register-user', payload);
  }

  async updateMfaSettingsEnabledChannels(
    user: MintUserModel,
    channel: MintAuthChannel,
    enabled: boolean,
    userSettings?: MintUserSettings,
  ): Promise<MintUserSettings> {
    if (!userSettings) {
      userSettings = await this.userService.getSettings(user);
    }

    const disabledChannels = userSettings?.auth?.mfa?.disabledChannels ?? [];
    const mfaEnabled = userSettings?.auth?.mfa?.enabled;
    const newSettings: MintUserSettings = { ...userSettings };

    if (enabled) {
      const index = disabledChannels.findIndex((ch) => ch === channel);

      if (index >= 0) {
        disabledChannels.splice(index, 1);
        newSettings.auth.mfa.disabledChannels = disabledChannels;
      }

      if (!mfaEnabled) {
        newSettings.auth.mfa.enabled = true;
      }
    }

    if (!enabled) {
      if (!disabledChannels.includes(channel)) {
        disabledChannels.push(channel);
      }

      newSettings.auth.mfa.disabledChannels = disabledChannels;

      if (
        disabledChannels.includes(MintAuthChannel.Sms) &&
        disabledChannels.includes(MintAuthChannel.Email)
      ) {
        newSettings.auth.mfa.enabled = false;
      }
    }

    return await this.userService.updateSettings(user, null, newSettings);
  }

  async logout(clearLocalStorage = true) {
    await this.authService.logout(clearLocalStorage);
    await lastValueFrom(
      this.localStorage.removeItem(
        ADMIN_LOGGED_IN_AS_USER_KEY,
        MintStorageType.Local,
      ),
    );
  }
}
