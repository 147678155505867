import { HttpErrorResponse } from '@angular/common/http';
import {
  MintAppService,
  MintAuthChannel,
  MintAuthService,
  MintAuthType,
  MintDebouncer,
  MintLogger,
  MintStorageType,
  MintUserModel,
  MintUserService,
  MintVerificationStatus,
} from '@bryllyant/mint-ngx';

import { lastValueFrom } from 'rxjs';
import { ErrorPageType, StatusType, TwoFactorChannel } from '../types';
import { ValidatorUtil } from '../utils/validator-util';
import { BaseController } from './base.controller';
import { BaseService } from './base.service';
import { VerificationService } from './verification.service';

const logger = MintLogger.getLogger('VerificationController');

export abstract class VerificationController extends BaseController {
  type: MintAuthType;
  channel: MintAuthChannel;

  user: MintUserModel | undefined;
  mfaChannelOptions: MintAuthChannel[];
  username: string;
  mobileNumber: string;
  redirectUrl: string;
  newSession: boolean;
  failedAttempts = 0;

  viewType: 'modal' | 'inline' | 'page';
  inputVerificationCode = ['', '', '', '', '', ''];

  // MFA
  twoFactorChannels: TwoFactorChannel[];
  isTwoFactorOptionsVisible: boolean;

  protected constructor(
    public baseService: BaseService,
    public authService: MintAuthService,
    public verificationService: VerificationService,
    public appService: MintAppService,
    public userService: MintUserService,
  ) {
    super(baseService);

    this.debouncer = new MintDebouncer(10);
  }

  async initMfaVerification() {
    let isMobileNotVerified = true;

    if (this.type === MintAuthType.Mfa) {
      // const { status } = await this.userService.getMobileVerificationStatus();
      // isMobileVerified = status === MintVerificationStatus.Verified;

      const session = await this.authService.getSession();

      logger.debug('initMfaVerification: session: ', session);

      isMobileNotVerified = (session.verification?.mobileNumber?.required &&
        session.user.mobileNumber &&
        session.verification?.mobileNumber?.status !==
          MintVerificationStatus.Verified) as boolean;

      logger.debug(
        'initMfaVerification: isMobileVerified: ',
        isMobileNotVerified,
      );

      if (
        isMobileNotVerified &&
        this.mfaChannelOptions &&
        this.mfaChannelOptions.includes(MintAuthChannel.Sms)
      ) {
        this.mfaChannelOptions.splice(
          this.mfaChannelOptions.indexOf(MintAuthChannel.Sms),
          1,
        );
      }
    }

    this.twoFactorChannels = this.buildTwoFactorVerificationChannels(
      this.mfaChannelOptions ?? [MintAuthChannel.Email, MintAuthChannel.Sms],
      this.user,
      this.username,
      this.mobileNumber,
    );

    this.newSession = true;

    if (this.twoFactorChannels?.length === 1) {
      this.channel = this.twoFactorChannels[0].type;
      return await this.initMfaChannel();
    }

    if (!this.user?.mobileNumber && !this.mobileNumber) {
      this.channel = MintAuthChannel.Email;
      return await this.initMfaChannel();
    }

    const channel = await lastValueFrom(
      this.baseService.localStorage.getItem('channel', MintStorageType.Session),
    );

    if (channel) {
      this.channel = channel;
    } else {
      this.isTwoFactorOptionsVisible = true;
    }
  }

  async initMfaChannel() {
    await this.requestCode();
    this.isTwoFactorOptionsVisible = false;
  }

  async requestCode() {
    await this.verificationService.requestCode(
      this.type,
      this.channel,
      this.username,
    );

    if (this.viewType !== 'modal') {
      this.toggleModal('successModal');
    }
  }

  async resendCode() {
    this.handleLoad(
      async () => {
        this.resetFields();
        this.statusAlert = null;

        try {
          await this.verificationService.resendCode(
            this.type,
            this.channel,
            this.username,
          );

          if (this.viewType !== 'modal') {
            this.toggleModal('successModal');
          } else {
            this.statusAlert = {
              type: StatusType.Success,
              message: 'Verification code successfully resent.',
            };
          }
        } catch (err) {
          this.statusAlert = {
            type: StatusType.Error,
            message: 'Error sending verification code.',
          };

          return Promise.reject();
        }
      },
      {
        disableErrorToast: true,
      },
    );
  }

  verifyCode() {
    this.handleLoad(
      async () => {
        this.statusAlert = null;

        if (this.inputVerificationCode.join('').length !== 6) {
          return Promise.reject('Please enter a valid code');
        }

        try {
          const session = await this.authService.verifyAuthCode({
            authCode: this.inputVerificationCode.join(''),
            username: this.user?.username ?? this.username,
            login: this.newSession,
            type: this.type,
            channel: this.channel,
          });

          if (this.viewType === 'page' && this.redirectUrl) {
            await this.navigate({ routerLink: this.redirectUrl });
          }

          if (this.viewType === 'modal' || this.viewType === 'inline') {
            this.verificationService.verificationResult.next({
              type: StatusType.Success,
              value: session,
            });
          }
        } catch (err: HttpErrorResponse | any) {
          this.failedAttempts = this.failedAttempts + 1;

          if (err?.error?.type === 'login-disabled') {
            await this.baseService.router.navigate(['/error'], {
              queryParams: {
                type: ErrorPageType.AccountLockedMaxFailedAttempts,
              },
            });
          }

          this.statusAlert = {
            type: StatusType.Error,
            message: 'Invalid verification code. Please check and try again.',
          };

          this.verificationService.verificationResult.next({
            type: StatusType.Error,
            value: err,
          });

          return Promise.reject();
        } finally {
          this.resetFields();
        }
      },
      {
        disableErrorToast: true,
      },
    );
  }

  triggerVerifyCode(event: KeyboardEvent | any) {
    const target: HTMLInputElement = event.target;
    target.blur();
    setTimeout(() => this.verifyCode(), 100);
  }

  buildTwoFactorVerificationChannels(
    channels: MintAuthChannel[],
    user: MintUserModel | undefined,
    username: string,
    mobileNumber?: string,
  ): TwoFactorChannel[] {
    const twoFactorChannels = [];

    for (const channel of channels) {
      switch (channel) {
        case MintAuthChannel.Email:
          twoFactorChannels.push({
            type: MintAuthChannel.Email,
            address: username ?? user?.email,
          });
          break;

        case MintAuthChannel.Sms:
          if (user?.mobileNumber || mobileNumber) {
            twoFactorChannels.push({
              type: MintAuthChannel.Sms,
              address: user?.mobileNumber || mobileNumber,
            });
          }
          break;

        case MintAuthChannel.Authenticator:
          twoFactorChannels.push({
            type: MintAuthChannel.Authenticator,
          });
          break;
      }
    }

    return twoFactorChannels;
  }

  async onCodeKeyDown(event: KeyboardEvent) {
    const target: HTMLInputElement | any = event.target;

    // Make sure key is a number and not the paste key
    if (
      ValidatorUtil.ALPHABETIC_REGEX.test(event.key) &&
      event.key.length === 1 &&
      event.key !== 'v'
    ) {
      return event.preventDefault();
    }

    // Only allow one character per input
    if (target.value.length > 0 && this.isNumeric(event.key)) {
      target.value = target.value.slice(1, 1);
    }

    // Don't move to next input if key isn't valid
    if (
      (!event.code || !this.isNumeric(event.key)) &&
      event.code !== 'Backspace'
    ) {
      return;
    }

    await this.debouncer.debounce(() => {
      let element: HTMLInputElement;

      if (event.code === 'Backspace' || event.key === 'Backspace') {
        if (target.value) {
          return;
        }

        element = target.previousElementSibling;
      } else {
        element = target.nextElementSibling;
      }

      if (element !== null) {
        element.focus();
        element.select();
      }
    });
  }

  onPaste(event: ClipboardEvent) {
    const code = event.clipboardData?.getData('text');

    event.preventDefault();

    if (code && code.length === 6 && this.isNumeric(+code)) {
      return (this.inputVerificationCode = code.split(''));
    }
  }

  resetFields() {
    this.inputVerificationCode = ['', '', '', '', '', ''];
  }

  idxTracker(index: number): number {
    return index;
  }

  isInputValid(): boolean {
    return !this.inputVerificationCode.filter((input) => input === '').length;
  }
}
