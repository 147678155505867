import { Provider } from '@angular/core';
import {
  MintAccountService,
  MintApiLogService,
  MintAppClientService,
  MintAppService,
  MintAuthCodeService,
  MintAuthLogService,
  MintAuthService,
  MintExecutionRequestService,
  MintExecutionResponseService,
  MintHelpdeskMessageService,
  MintMessageService,
  MintUserAccountService,
  MintUserRoleService,
  MintUserService,
  MintWebhookClientService,
  MintWebhookLogService,
  MintWebhookService,
  MintWebhookSubscriptionService,
} from '@bryllyant/mint-ngx';
import { SystemService } from '@fynvana/common';

import { MockAuthService } from './mint-services/auth.service.mock';
import { MockUserService } from './mint-services/user.service.mock';

export class TestProvider {
  // If mock services need to be injected into other mock services, provide them here
  static PROVIDED_MOCK_SERVICES = [MockUserService];

  static getTestProviders(): Provider[] {
    return [
      {
        provide: MintAuthService,
        useClass: MockAuthService,
      },
      {
        provide: MintUserService,
        useClass: MockUserService,
      },
      // TODO: MOCK
      {
        provide: MintAccountService,
        useValue: {},
      },
      {
        provide: MintUserAccountService,
        useValue: {},
      },
      {
        provide: MintUserRoleService,
        useValue: {},
      },
      {
        provide: MintAppService,
        useValue: {},
      },
      {
        provide: MintAppClientService,
        useValue: {},
      },
      {
        provide: MintExecutionRequestService,
        useValue: {},
      },
      {
        provide: MintExecutionResponseService,
        useValue: {},
      },
      {
        provide: MintHelpdeskMessageService,
        useValue: {},
      },
      {
        provide: MintApiLogService,
        useValue: {},
      },
      {
        provide: MintAuthLogService,
        useValue: {},
      },
      {
        provide: MintAuthCodeService,
        useValue: {},
      },
      {
        provide: MintMessageService,
        useValue: {},
      },
      {
        provide: SystemService,
        useValue: {},
      },
      {
        provide: MintWebhookService,
        useValue: {},
      },
      {
        provide: MintWebhookClientService,
        useValue: {},
      },
      {
        provide: MintWebhookLogService,
        useValue: {},
      },
      {
        provide: MintWebhookSubscriptionService,
        useValue: {},
      },
    ];
  }
}
