import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  MintAuthService,
  MintFormController,
  MintHelpdeskMessageModel,
  MintHelpdeskMessageService,
  MintLogger,
  MintMobileNumberValidator,
  MintSession,
  MintUserModel,
} from '@bryllyant/mint-ngx';
import {
  AuthSection,
  BaseService,
  FormController,
  SystemService,
} from '@fynvana/common';
import { contactInfoForm, issueForm } from './form';

const logger = MintLogger.getLogger('ContactPageComponent');

@Component({
  selector: 'fynvana-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent extends FormController implements OnInit {
  contactInfoForm: MintFormController | undefined;
  contactInfoFormFields: any;

  issueForm: MintFormController | undefined;
  issueFormFields: any;

  ticketSubmitted = false;
  session: MintSession;
  user: MintUserModel;
  successEmailParam: string;
  redirectedFrom: AuthSection | string;

  constructor(
    public baseService: BaseService,
    public mobileNumberValidator: MintMobileNumberValidator,
    public location: Location,
    private readonly helpdeskMessageService: MintHelpdeskMessageService,
    private readonly authService: MintAuthService,
    private readonly systemService: SystemService,
    private readonly route: ActivatedRoute,
  ) {
    super(baseService);

    this.route.queryParams.subscribe(
      async ({ status, email, redirectedFrom }) => {
        this.ticketSubmitted = status === 'submitted';
        this.successEmailParam = email;
        this.redirectedFrom = redirectedFrom;

        await this.determineShouldLogout();
      },
    );
  }

  get isAuthenticated(): boolean {
    return !!this.session && !!this.user;
  }

  ngOnInit() {
    this.handleLoad(async () => {
      this.redirectedFrom = this.route.snapshot.queryParams.redirectedFrom;
      await this.determineShouldLogout();

      this.user = await this.authService.me();
      this.session = await this.authService.getSession();

      const contactInfoFormAndFields = contactInfoForm(this);
      const issueFormAndFields = issueForm(this);

      if (!this.isAuthenticated) {
        this.contactInfoForm = contactInfoFormAndFields.form;
        this.contactInfoFormFields = contactInfoFormAndFields.fields;
      }

      this.issueForm = issueFormAndFields.form;
      this.issueFormFields = issueFormAndFields.fields;
    });
  }

  onSubmit() {
    this.handleSubmit(async () => {
      if (!this.issueForm) return;

      if (!this.isAuthenticated && this.contactInfoForm) {
        await this.contactInfoForm.triggerFormValidation();
        if (!this.contactInfoForm.valid) {
          return Promise.reject(this.messages.requiredFields);
        }
      }

      await this.issueForm.triggerFormValidation();

      if (!this.issueForm.valid) {
        return Promise.reject(this.messages.requiredFields);
      }

      const contactData = this.contactInfoForm?.formValues();
      const issueData = this.issueForm.formValues();
      let nameParts;

      if (!this.isAuthenticated && contactData) {
        nameParts = contactData['fullName'].split(' '); // Maybe we should collect the first/last names discretely?
      }

      const model = new MintHelpdeskMessageModel({
        firstName: this.user?.firstName ?? nameParts[0],
        lastName: this.user?.lastName ?? nameParts.slice(1).join(' '),
        email: contactData ? contactData['email'] : this.user?.email,
        mobileNumber: contactData
          ? contactData['mobileNumber'].phoneNumber
          : this.user?.mobileNumber,
        message: issueData['description'],
        context: {
          issueType: issueData['issueType'],
        },
      });

      await this.helpdeskMessageService.create(model);
      await this.baseService.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          status: 'submitted',
          email: contactData ? contactData['email'] : this.user?.email,
          redirectedFrom: this.redirectedFrom,
        },
      });
    });
  }

  async determineShouldLogout() {
    // get rid of any session data if coming from mfa / verification screens
    if (
      Object.values(AuthSection).includes(this.redirectedFrom as AuthSection)
    ) {
      await this.systemService.logout();
    }
  }
}
