import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisableAutofillDirective } from './directives/disable-autofill.directive';
import { OffClickDirective } from './directives/off-click.directive';
import { BigNumberToNumberPipe } from './pipes/big-number-to-number.pipe';
import { CamelizePipe } from './pipes/camelize.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ReadTimePipe } from './pipes/read-time.pipe';
import { UserFirstLastPipe } from './pipes/user-first-last.pipe';

const imports = [CommonModule];
const declarations = [
  CamelizePipe,
  FileSizePipe,
  BigNumberToNumberPipe,
  OffClickDirective,
  DisableAutofillDirective,
  ReadTimePipe,
  UserFirstLastPipe,
];

@NgModule({
  imports,
  declarations,
  exports: [...declarations],
})
export class CommonLibModule {}
