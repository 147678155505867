import { plainToInstance } from 'class-transformer';
import { IsArray, IsEnum, IsString } from 'class-validator';
import { TaxFormNumber } from '../tax.types';
import { IrsFormTypeOfTax } from './irs-form.types';

export class IrsFormAuthorizationRequest {
  @IsEnum(IrsFormTypeOfTax, { each: true })
  typeOfTax: IrsFormTypeOfTax | IrsFormTypeOfTax[];

  @IsEnum(TaxFormNumber, { each: true })
  @IsArray()
  taxForms: TaxFormNumber[];

  @IsString({ each: true })
  @IsArray()
  years: string[];

  @IsString()
  specificTaxMatters?: string;

  constructor(model?: Partial<IrsFormAuthorizationRequest>) {
    if (model) {
      Object.assign(this, plainToInstance(IrsFormAuthorizationRequest, model));
    }
  }
}
