import { HttpErrorResponse } from '@angular/common/http';
import { TemplateRef } from '@angular/core';

import {
  MintDebouncer,
  MintFormFieldOption,
  MintLogger,
} from '@bryllyant/mint-ngx';
import {
  AlertOptions,
  DefaultSEOImgs,
  LoadingOptions,
  MenuItem,
  SiteMetaDataOverrides,
  StatusAlert,
} from '../types';
import { AlertUtil } from '../utils/alert-util';
import { ParseUtil } from '../utils/parse-util';

import { BaseService } from './base.service';

const logger = MintLogger.getLogger('BaseController');

export abstract class BaseController {
  uid: string;
  isLoading = false;
  debouncer: MintDebouncer;
  statusAlert: StatusAlert | null;
  breadcrumbs: MenuItem[];
  modalsVisibleMap: Map<string, boolean> = new Map();

  messages = {
    requiredFields: 'Please fill out the required fields.',
  };

  protected constructor(public baseService: BaseService) {}

  /** Sets SEO Meta data, if used, should be overwritten on a page by page basis */
  setSEO(
    seoOverrides: SiteMetaDataOverrides = {},
    defaultImgs?: DefaultSEOImgs,
  ) {
    this.baseService.seoService.setSEO(seoOverrides, defaultImgs);
  }

  /**
   * Sets isLoading while passed in code block runs
   * then notifies on resolve or reject.
   *
   * EXAMPLE USAGE: this.handleLoad(async() => { await this.compFn(); });
   */
  handleLoad(fn: () => Promise<void>, options: LoadingOptions = {}) {
    this.toggleLoadingSpinner(true, options);

    fn()
      .then(() => {
        this.toggleLoadingSpinner(false, options);

        if (options.successMessage) {
          this.notify({
            success: options.successMessage,
          });
        }
      })
      .catch((err: HttpErrorResponse | string) => {
        this.toggleLoadingSpinner(false, options);

        if (!options.disableErrorToast) {
          this.notify({
            error: ParseUtil.errorToString(options.errorMessage ?? err),
          });
        }
      });
  }

  /**
   * Toggles input variables for loading spinners.
   *
   * Use <fynvana-loader> in component markup and disable the
   * global loading spinner for more customization.
   */
  toggleLoadingSpinner(isLoading: boolean, options: LoadingOptions = {}) {
    this.isLoading = isLoading;

    if (isLoading) {
      this.baseService.layoutState.globalLoadingMessage =
        options.globalLoadingMessage;
    } else {
      this.baseService.layoutState.globalLoadingMessage = undefined;
    }

    if (!options.disableGlobalLoad) {
      this.baseService.toggleLoading.next(isLoading);
    }
  }

  /** Display a toast notification. */
  notify(options: AlertOptions = {}) {
    AlertUtil.notify(this.baseService.notificationService, options);
  }

  slugToTitleCase(slug: string | undefined): string {
    return ParseUtil.slugToTitlecase(slug);
  }

  parseSsn(ssn: string | undefined, obfuscate = false) {
    if (!ssn) return '--';
    return ParseUtil.parseSsn(ssn, obfuscate);
  }

  parseEin(ein: string | undefined) {
    if (!ein) return '--';
    return ParseUtil.parseEin(ein);
  }

  wordsToSlug(string: string | undefined): string {
    return ParseUtil.wordsToSlug(string);
  }

  capitalizeFirst(text: string): string {
    return ParseUtil.capitalizeFirst(text);
  }

  /** Returns a promise for simulated delay/loading */
  sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isNumeric(value: any): boolean {
    return ParseUtil.isNumeric(value);
  }

  truncateString(
    value: string | undefined,
    maxLength: number = 100,
    ellipsisPlace?: number,
  ): string {
    if (!value) return '--';

    return ParseUtil.truncateString(value, maxLength, ellipsisPlace);
  }

  /** Refreshes current page, in an 'Angular' way */
  async reloadPage() {
    const router = this.baseService.router;
    const currentUrl = router.url;
    await router.navigateByUrl('/', { skipLocationChange: true });
    await router.navigate([currentUrl]);
  }

  /** Determine how to navigate based whether MenuItem has a url or routerLink */
  async navigate(menuItem: MenuItem, delay?: number) {
    logger.debug('navigate: menuItem: ', menuItem);

    const { url, routerLink, queryParams, urlTarget, data } = menuItem;

    if (delay) {
      await this.sleep(delay);
    }

    if (url) {
      urlTarget === '_blank'
        ? window.open(url, urlTarget)
        : (window.location.href = url);
    } else if (routerLink) {
      queryParams
        ? await this.baseService.router.navigate([routerLink], {
            queryParams,
          })
        : await this.baseService.router.navigateByUrl(routerLink as string);
    } else if (data?.action) {
      this.baseService.actionTriggered.next(data.action);
    } else {
      return logger.error('Invalid menuItem');
    }
  }

  /** Toggles modal visiblity variable, given the object key */
  toggleModal(key: string, val?: boolean) {
    if (this.modalsVisibleMap.has(key)) {
      const currentValue = this.modalsVisibleMap.get(key);
      this.modalsVisibleMap.set(key, val ?? !currentValue);
      return;
    }

    this.modalsVisibleMap.set(key, true);
    logger.debug('toggleModal: called for key: ', key);
  }

  async copyToClipboard(text: string | undefined, label?: string) {
    if (!text) return;
    await navigator.clipboard.writeText(text);
    this.notify({ success: `Copied ${label || 'text'} to clipboard!` });
  }

  deepCloneArray(arr: any): any[] {
    return JSON.parse(JSON.stringify(arr));
  }

  areArraysEqual(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((v, i) => v === b[i]);
  }

  formatUserFirstLast(user: {
    firstName: string | null;
    lastName: string | null;
  }): string {
    return ParseUtil.formatUserFirstLast({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
    });
  }

  isTemplateRef(value: string | TemplateRef<any>): value is TemplateRef<any> {
    return !!value && typeof value !== 'string';
  }

  toPrismMarkdown(data: string, format = 'json') {
    return '```' + format + '\n' + data + '\n```';
  }

  enumToFilterOptions(enumValues: object): MintFormFieldOption[] {
    return Object.values(enumValues).map((value) => {
      return { name: this.slugToTitleCase(value as string), value };
    });
  }
}
