import { MintError } from '@bryllyant/mint-ngx';
import { plainToInstance } from 'class-transformer';
import { IsArray, IsEnum, IsInt, IsString } from 'class-validator';
import * as dayjs from 'dayjs';
import { TaxFormNumber, TaxPeriod } from '../tax.types';
import { getDateForPeriod } from '../util';
import { IrsFormTypeOfTax } from './irs-form.types';

export class IrsFormAuthorization {
  @IsEnum(IrsFormTypeOfTax, { each: true })
  @IsArray()
  readonly typeOfTax: IrsFormTypeOfTax[];

  @IsEnum(TaxFormNumber)
  readonly taxFormNumber: TaxFormNumber;

  @IsInt()
  readonly startTaxYear: number;

  @IsEnum(TaxPeriod)
  readonly startTaxPeriod: TaxPeriod;

  @IsInt()
  readonly endTaxYear: number;

  @IsEnum(TaxPeriod)
  readonly endTaxPeriod: TaxPeriod;

  @IsString()
  readonly specificMatters: string;

  constructor(model?: Partial<IrsFormAuthorization>) {
    if (model) {
      Object.assign(this, plainToInstance(IrsFormAuthorization, model));

      // validate dates
      const startDate = dayjs(
        `${this.startTaxYear}-${getDateForPeriod(this.startTaxPeriod)}`,
      );

      const endDate = dayjs(
        `${this.endTaxYear}-${getDateForPeriod(this.endTaxPeriod)}`,
      );

      if (endDate.isBefore(startDate)) {
        throw new MintError({
          message: 'Invalid start and end dates',
          data: this,
        });
      }
    }
  }
}
