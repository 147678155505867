import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {
  MintAccountStatus,
  MintAuthChannel,
  MintAuthService,
  MintAuthType,
  MintLogger,
  MintSession,
  MintUserService,
  MintUserStatus,
  MintVerificationStatus,
  serializeQueryParams,
} from '@bryllyant/mint-ngx';
import {
  AppIdentifier,
  BaseController,
  BaseService,
  ErrorPageType,
  SystemService,
} from '@fynvana/common';
import { TaxpayerOnboardingService } from '../main/taxpayer/onboarding/taxpayer-onboarding.service';

const logger = MintLogger.getLogger('AuthGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BaseController {
  constructor(
    public baseService: BaseService,
    private readonly router: Router,
    private readonly authService: MintAuthService,
    private readonly userService: MintUserService,
    private readonly systemService: SystemService,
    private readonly taxpayerOnboardingService: TaxpayerOnboardingService,
  ) {
    super(baseService);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const session: MintSession | null = await this.authService.getSession();
    const app: AppIdentifier = route.data.app;

    // User is not authed, redirect to login
    if (!session) {
      await this.router.navigate(['/auth/login'], {
        queryParams: {
          redirect: state.url,
        },
      });
      return false;
    }

    // User has not verified email (signup flow ordering)
    if (
      session.verification?.email?.required &&
      session.verification?.email?.status !== MintVerificationStatus.Verified
    ) {
      const params = serializeQueryParams({
        type: MintAuthType.EmailVerification,
        channel: MintAuthChannel.Email,
        username: session.user.email,
        redirect: '/dashboard',
        newSession: true,
      });

      await this.router.navigateByUrl(`/auth/login/verification?${params}`);
      return false;
    }

    // User has not saved mobile number (signup flow ordering)
    // if (
    //   session.verification?.mobileNumber?.status ===
    //   MintVerificationStatus.NotFound
    // ) {
    //   await this.router.navigateByUrl(`/auth/login/register-mobile`);
    //   return false;
    // }

    if (
      !session.user.dynamicSettings?.mobileRegistrationSkipped &&
      !session.user.mobileNumber
    ) {
      await this.router.navigateByUrl(`/auth/login/register-mobile`);
      return false;
    }

    const isMobileNotVerified: boolean = (session.verification?.mobileNumber
      ?.required &&
      session.user.mobileNumber &&
      session.verification?.mobileNumber?.status !==
        MintVerificationStatus.Verified) as boolean;

    // User has not verified phone (signup flow ordering)
    if (isMobileNotVerified) {
      const params = serializeQueryParams({
        type: MintAuthType.MobileVerification,
        channel: MintAuthChannel.Sms,
        username: session.user.email,
        redirect: '/dashboard',
        newSession: true,
      });

      await this.router.navigateByUrl(`/auth/login/verification?${params}`);
      return false;
    }

    // MFA is required
    if (session.mfa?.required) {
      const params = serializeQueryParams({
        type: MintAuthType.Mfa,
        channels: session.mfa.channels,
        username: session.user.email,
        redirect: state.url ?? '/dashboard',
      });

      await this.router.navigateByUrl(`/auth/login/verification?${params}`);
      return false;
    }

    // Check account or user is disabled
    const account = await this.userService.getDefaultAccount(session.user);
    if (
      account?.status === MintAccountStatus.Disabled ||
      session?.user?.status === MintUserStatus.Disabled
    ) {
      await this.systemService.logout();
      await this.router.navigate(['/error'], {
        queryParams: { type: ErrorPageType.AccountLocked },
      });
      return false;
    }

    // If taxpayer portal, check to make sure user is onboarded
    if (app === AppIdentifier.Taxpayer) {
      logger.debug('checking if user has been onboarded ...');

      if (
        !(await this.taxpayerOnboardingService.isUserOnboarded(session.user))
      ) {
        await this.router.navigateByUrl('/onboarding', {
          onSameUrlNavigation: 'reload',
        });
        return false;
      }
    }

    return true;
  }
}
